import * as React from "react";
import styled from "@emotion/styled";
import {useEffect, useMemo, useState} from "react";
import ManualLayout, {ManualLayoutMenu} from "../../components/pages/manual/ManualLayout";
import * as queryString from "query-string";
import jsonRequest from "../../utils/request/jsonRequest";
import {message} from "antd";
import ManualBody from "../../components/pages/manual/ManualBody";
import Seo from "../../components/seo";
import {navigate} from "gatsby";
import Serialize from "../../components/editor/serialize";

const Content = styled.div`
  line-height: 22px;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC,sans-serif;
  font-weight: 400;
  color: rgba(0,0,0,0.8);
`

const About = ({location}) => {

  const [loadingMenu, setLoadingMenu] = useState(false)
  const [loadingData, setLoadingData] = useState(false)
  const [menu, setMenu] = useState<ManualLayoutMenu[]>([])
  const [data, setData] = useState<any>([])

  const params: any = useMemo(() => {
    return {
      ...queryString.parse(location.search),
      type: 'about',
    }
  }, [location])

  const selected = useMemo<string>(() => {
    return (params.id ?? '') as string
  }, [params])

  const contentInnerHtml = useMemo(() => {
    let content = [{"type":"paragraph","children":[{"text":data.content ?? ""}]}]
    try {
      content = JSON.parse(data.content)
    } catch {}
    return {__html: content.map(n => Serialize(n)).join('')}
  }, [data])

  const loadMenu = () => {
    if (loadingMenu && params.type) {
      return;
    }
    setLoadingMenu(true)
    jsonRequest(`/manual?type=${params.type}`).then((rsp) => {
      if (rsp.success) {
        setMenu(rsp.data.data.map(item => ({
          id: item.id,
          text: item.title,
          href: `/about?id=${item.id}`
        })))
        if (rsp.data.data.length > 0) {
          if (params.id == null) {
            navigate(`/about?id=${rsp.data.data[0].id}`);
          }
        }
      } else {
        message.error(rsp.message)
      }
    }).catch(console.error).finally(() => {
      setLoadingMenu(false)
    })
  }

  useEffect(() => {
    loadMenu()
  }, [params.type])

  useEffect(() => {
    if (loadingData || !params.id) {
      return ;
    }
    setLoadingData(true);
    jsonRequest(`/manual/${params.id}`).then(rsp => {
      if (rsp.success) {
        setData(rsp.data.data)
      } else {
        message.error(rsp.message)
      }
    }).catch(console.error).finally(() => {
      setLoadingData(false)
    })
  }, [params])

  return (<>
    <ManualLayout {...{menu, selected, loading: loadingMenu}}>
      <ManualBody
        title={data.title}
        loading={loadingData}
      >
        {/*<Content>*/}
        {/*  {data.content}*/}
        {/*</Content>*/}
        <Content dangerouslySetInnerHTML={contentInnerHtml} />
      </ManualBody>
    </ManualLayout>
  </>)
}

export default About;

export const Head = () => <Seo title='GitWork-关于我们'/>
